import styled from 'styled-components'
import { Row, Col, Container } from 'react-bootstrap'
import { BsCaretRightFill, BsCaretLeftFill} from 'react-icons/bs'

export const BlogContainer = styled.div`
    background-image: url(${({ img }) => (img)});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
    height: auto;
    
    @media screen and (max-width: 768px) {
        background-position: center;
    }
`

export const BlogOpacityApplier = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    min-height: 100vh;
    height: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
`

export const BlogWrapper = styled.div`
    border: 2px solid black;
    opacity: 90%;
    min-height: 80vh;
    height: auto;
    overflow: auto;
    margin-right: 10vw;
    margin-left: 10vw;
    background-color: white;
    z-index: 1;
    clear: both;
`

export const BlogHeader = styled.h1`
    color: rgba(21, 175, 252, 1);
    opacity: 100%;
    font-size: 50px;
    padding-left: 3vw;
    padding-top: 3vh;
`

export const BlogFeedContainer = styled(Container)`
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 0;
    padding-right: 0;
    height: auto;
    position: relative;
`

export const BlogFeedRow = styled(Row)`
    margin-left: 2vw;
    margin-right: 2vw;
    padding-bottom: 5vh;

    @media screen and (max-width: 768px) {
        padding-bottom: 0;
    }
`

export const MediumArticleContainer = styled(Col)`
    border: 1px solid black;
    border-radius: 5px;
    padding-top: 2vh;
    padding-bottom: 2vh;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 4vh;
    padding-left: 1vw;
    padding-right: 1vw;
`

export const MediumArticleTitle = styled(Row)`
    margin-left: 1vw;
    margin-right: 1vw;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 1vh;
    position: relative;
    color: black;
`

export const MediumArticleImage = styled.img`
    margin-top: 1vh;
    width: 100%;
`

export const FeedPageNextArrow = styled(BsCaretRightFill)`
    font-size: 20px;
    margin-left: 1vw;

    &:hover {
        background-color: gray;
        cursor: pointer;
    }
`

export const FeedPagePreviousArrow = styled(BsCaretLeftFill)`
    font-size: 20px;
    margin-right: 1vw;

    &:hover {
        background-color: gray;
        cursor: pointer;
    }
`

export const FeedPageControls = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3vh;

    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
    }
`
