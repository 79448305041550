import styled from 'styled-components'

export const AboutContainer = styled.div`
    height: auto;
    display: block;
    overflow: auto;
    clear: both;
`

export const AboutHeader = styled.h1`
    color: rgba(21, 205, 252, 1);
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 2vh;
    font-size: 7.5vh;
`

export const Divider = styled.hr`
    width: 50vw;
    margin: auto;
    border-top: 1px solid darkgray;

    @media screen and (min-width: 1024px) {
        width: 512px;
    }
`

export const AboutContentContainer = styled.div`
    padding-left: 5vh;
    padding-bottom: 5vh;
    padding-right: 5vh;
    overflow: auto;
`

export const ImageCropper = styled.div`
    float: right;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 12vh;
    margin-right: 5vw;
    margin-left: 5vw;
    border: 3px solid gray;
    width: 30vw;
    height: 30vw;

    img {
        display: inline;
        margin: 0 auto;
        height: 100%;
        width: 100%;
    }

    &:hover {
        border: 5px solid gray;
    }
`

export const AboutParagraphContent = styled.div`
    padding-top: 8vh;
    padding-left: 7vw;
`
