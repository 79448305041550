import React from 'react'
import { LandingContainer, LandingWrapper, TextContainer, TextHeader, SecondaryHeader, Divider } from "./LandingElements"
import backgroundImage from '../../images/ocean.jpg';

const LandingView = () => {
    return (
        <>
            <LandingContainer  img={backgroundImage}>
                <LandingWrapper>
                    <TextContainer>
                        <TextHeader>
                            Sajan Gutta
                        </TextHeader>
                        <Divider>
                        </Divider>
                        <SecondaryHeader>
                            Full-Stack SWE
                        </SecondaryHeader>
                    </TextContainer>
                </LandingWrapper>
            </LandingContainer>
        </>
    )
}

export default LandingView
