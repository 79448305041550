import styled from 'styled-components'
import { AiOutlineLink } from 'react-icons/ai';

export const ProjectsViewContainer = styled.div`
    height: auto;
    display: block;
    overflow: auto;
    clear: both;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;

    @media screen and (max-width: 768px) {
        height: auto;
    }
`

export const ProjectsViewHeader = styled.h1`
    color: rgba(21, 205, 252, 1);
    text-align: center;
    padding-bottom: 2vh;
    font-size: 7.5vh;
`

export const Divider = styled.hr`
    width: 50vw;
    margin: auto;
    border-top: 1px solid darkgray;

    @media screen and (min-width: 1024px) {
        width: 512px;
    }
`

export const WebsiteDisclaimer = styled.div`
    clear: both;
    width: 100%;
    text-align: center;
    padding-top: 10vh;
    font-weight: bold;
`

export const ProjectContainer = styled.div`
    padding-top: 10vh;
    height: auto;
    width: 100%;
    clear: both;
`

export const ProjectImageContainer = styled.div`
    max-height: ${({ projHeight }) => (projHeight)};
    max-width: 100%;
    height: auto;
    width: auto;
    float: ${({ isRight }) => (isRight ? 'right' : 'left')};
    overflow: hidden;
    width: ${({ imgWidth }) => (imgWidth)};
    border-radius: 5px;
    position: relative;

    @media screen and (max-width: 850px) {
        width: 100%;
        height: auto;
        display: flex;
    }
`

export const ProjectMainImage = styled.img`
    max-height: inherit;
    height: inherit;
    width: inherit;
    border-radius: 5px;
    float: top;
    display: flex;
    border: 1px solid black;

    @media screen and (max-width: 850px) {
        float: bottom;
    }
`

export const ProjectMainImageFilter = styled.div`
    height: 100%;
    position: absolute;
    top: 0;
    width: inherit;
    background-color: rgba(21, 205, 252, 1);
    opacity: 0%;
    border-radius: 5px;

    &:hover {
        opacity: 20%;
        cursor: pointer;
    }
`

export const ProjectContent = styled.div`
    text-align: ${({ isRight }) => (isRight ? 'right' : 'left')};
    position: relative;
    height: 100%;
    float: ${({ isRight }) => (isRight ? 'right' : 'left')};
    width: 35%;

    @media screen and (max-width: 850px) {
        float: none;
        width: 100%;
        text-align: left;
        height: auto;
        z-index: -1;
        padding-bottom: 3vh;
    }
`

export const ProjectHeader = styled.h1`
    text-align: inherit;
    font-size: 2.3vw;
    color: blue;

    @media screen and (max-width: 850px) {
        font-size: 30px;
    }
`

export const ProjectDescription = styled.p`
    padding-top: 3vh;
    text-align: inherit;
`

export const ProjectLinkIcon = styled(AiOutlineLink)`
    color: ${({ isWhite }) => (isWhite ? 'white' : 'black')};
    position: absolute;
    top: 0;
    right: 0;
`;
