import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar'
import LandingView from './components/LandingView'
import {BrowserRouter as Router} from 'react-router-dom';
import AboutView from './components/AboutView';
import ExperienceView from './components/ExperienceView';
import ProjectsView from './components/ProjectsView';
import BlogView from './components/BlogView';
import ContactView from './components/ContactView';
import ContactBar from './components/ContactBar'

const App = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  }

  return (
    <Router>
      <Sidebar isOpen={isOpen} toggle={toggleSidebar}/>
      <Navbar toggle={toggleSidebar}/>
      <ContactBar />
      <LandingView></LandingView>
      <AboutView></AboutView>
      <ExperienceView></ExperienceView>
      <ProjectsView></ProjectsView>
      <BlogView></BlogView>
      <ContactView></ContactView>
    </Router>
  );
}

export default App;
