import React from 'react'
import {
    AboutContainer, AboutHeader, Divider, AboutContentContainer, ImageCropper,
    AboutParagraphContent
} from './AboutElements'
import gradPic from '../../images/gradpic.png';

const AboutView = () => {
    return (
        <>
            <AboutContainer id="about">
                <AboutHeader>About Me</AboutHeader>
                <Divider></Divider>
                <AboutContentContainer>
                    <ImageCropper>
                        <img src={gradPic} alt="Sajan Gutta's headshot" />
                    </ImageCropper>
                    <AboutParagraphContent>
                        <h2>Hi, I'm Sajan! </h2>
                        <br />
                        <p>
                            I'm a Software Engineer and USC grad based in Los Angeles. While I specialize in backend, I'm a full-stack engineer with in-depth experience with frontend, mobile, DevOps, blockchain, and more.
                        </p>
                        <br />
                        <p>
                            I'm currently freelancing, working as CTO for a healthcare staffing app, and working on some personal projects.
                            Some of my previous experience includes engineering roles at Autograph, Samsara, and Discotech.
                        </p>
                        <br />
                        <p>
                            I recently graduated (December 2022) from the <strong>University of Southern California</strong> with my M.S. in Computer Science.
                            Before that, I earned my Bachelor's degree through USC in Computer Science and Business Administration, which I completed in December 2021.
                        </p>
                        <br />
                        <p>
                            Some of my personal hobbies include Tennis, Chess, Piano, Beach Volleyball, and Basketball.
                        </p>
                        <br />
                        <p>
                            I'm always down to meet new people so feel free to reach out through one of the links in the <strong>Contact</strong> section!
                        </p>
                    </AboutParagraphContent>
                </AboutContentContainer>
            </AboutContainer>
        </>
    )
}

export default AboutView
