import React from 'react'
import { ProjectsViewContainer, ProjectsViewHeader, Divider, WebsiteDisclaimer } from './ProjectViewElements'
import { ProductJobsBoardView, PersonalScoreboardView, MacAPIToolView, GitHubPortfolioView } from './ProjectElements'

const ProjectsView = () => {
    return (
        <>
            <ProjectsViewContainer id="projects">
                <ProjectsViewHeader>
                    Projects
                </ProjectsViewHeader>
                <Divider />
                <ProductJobsBoardView></ProductJobsBoardView>
                <PersonalScoreboardView></PersonalScoreboardView>
                <MacAPIToolView></MacAPIToolView>
                <GitHubPortfolioView></GitHubPortfolioView>
                <WebsiteDisclaimer>This website is also one of my projects and was built with React.</WebsiteDisclaimer>
            </ProjectsViewContainer>
        </>
    )
}

export default ProjectsView
