import React from 'react'
import {
    ExperienceContent, ExperienceContentHeader, BlueSpan, BiggerExperienceContentHeader,
    ExperienceTimeHeader, ExperienceList
} from './ExperienceElements'

export const EducationContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Student<BlueSpan> @ University of Southern California</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>August 2018 - December 2022</ExperienceTimeHeader>
                <ExperienceList>
                    <li>Graduated with M.S. in Computer Science in December 2022. <strong>Earned Summa Cum Laude honors with 3.94 GPA.</strong></li>
                    <li>Graduated from undergrad at USC in December 2021 with a Bachelor's of Science in Computer Science and Business Administration.</li>
                    <li><strong>Honors: </strong>Viterbi Top Scholars Award + Scholarship, USC Presidential Scholar, Honors student, Dean’s List every semester.</li>
                    <li><strong>Cumulative GPA: </strong>3.92 (Summa Cum Laude)</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const FreelanceContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Freelance Full-Stack SWE<BlueSpan> @ SJ GUTTA LLC</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>April 2023 - Present</ExperienceTimeHeader>
                <ExperienceList>
                    <li>I've freelanced on various projects as a full-stack SWE.</li>
                    <br />
                    <h4 style={{ textDecoration: 'underline' }}>Seventh Spark</h4>
                    <li>Building a subscription-based peer-to-peer live streaming and therapy platform.</li>
                    <li>
                        Working on both the frontend and backend to build core features including the broadcasting tool, chat, floating emoji reactions, Stripe subscription flow, and more.
                    </li><li>
                        <strong>Technologies Used:</strong> Next.JS, Express, and MongoDB
                    </li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const MedworxContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>CTO<BlueSpan> @ worx360</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>April 2023 - Present</ExperienceTimeHeader>
                <ExperienceList>
                    <li>CTO for mobile healthcare staffing app. Responsible for implementing core features including onboarding flow, job postings and applications, work hour logging, in-app chat, candidate matching algorithm, background checks,  and payroll directly through the app.</li>
                    <li>As CTO, I also help deploy and productionize the app as it rolls out to its first hospitals and surgery centers.</li>
                    <li><strong>Technologies Used:</strong> Swift, UIKit, NestJS, PostgreSQL</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const AutographContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>API/Backend Engineer<BlueSpan> @ Autograph</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>June 2022 - April 2022</ExperienceTimeHeader>
                <ExperienceList>
                    <li>Designed and implemented core services including token-gated experiences, live streaming, video-on-demand, notifications service, collector score calculation, etc.</li>
                    <li>Maintained and added new features to other services including the core API, minting, NFT metadata and ownership syncing, contract deployment, IPFS pinning, drop management, and more.</li>
                    <li>Took responsibilities beyond original backend job including management of internal npm packages, being a core contributor to internal CMS, handling direct requests from product/marketing teams, frontend debugging, and interfacing with vendors.</li>
                    <li><strong>Technologies Used:</strong>  TypeScript, Express, MongoDB, AWS Lambda, Serverless, Vue/Nuxt, Docker, AWS IVS, AWS MediaLive, etc.</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const DiscotechContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Backend Engineer<BlueSpan> @ Discotech</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>November 2018 - May 2022</ExperienceTimeHeader>
                <ExperienceList>
                    <li>Worked part-time since 2018 as a developer on a wide range of tasks in Discotech’s backend (largely in Python Flask) and API.</li>
                    <li>Also work on frontend enhancements to accompany new backend features, gaining experience with the full stack.</li>
                    <li>Designed an event recommendation system with Tensorflow integrating Firebase for tracking user interactions in the app and website.</li>
                    <li>Worked on many API integrations including Spotify, Apple Music, Ticketmaster, Eventbrite, Facebook, Firebase, S3, and more.</li>
                    <li>Regularly implement backend for new features including database schema updates, ORM models, and Python backend logic.</li>
                    <li>Implemented event review prioritization system, developed features in Discotech’s admin website, migrated code base to Python 3, developed object-oriented redirect system, enacted venue popularity system, improved Sentry error capturing, and more.</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const SamsaraContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Software Engineering Intern<BlueSpan> @ Samsara</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>Summer 2021</ExperienceTimeHeader>
                <ExperienceList>
                    <li>Interned on the Developer Ecosystems team working on Samsara’s API, Webhooks, and all integrations and tooling for external devs.</li>
                    <li>Designed and implemented a new API documentation and changelog publishing pipeline. This runs multiple times a day and automatically generates Samsara’s API specification. The published results are found at <a href="https://developers.samsara.com/reference">https://developers.samsara.com/reference.</a></li>
                    <li>Architectured and delivered a developer notifications feature complete with frontend web pages, a GraphQL layer, gRPC service, models layer, SQS worker, webhooks, Mailgun integration, and more. This allows Samsara developers to subscribe to desired notification types. Developer notifications can now be triggered from any service in the Samsara stack.</li>
                    <li><strong>Technologies Used:</strong> Go, TypeScript, MySQL, GraphQL, AWS SQS, Datadog, Terraform, gRPC, S3, Docker, Jira, LaunchDarkly, etc.</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const TroyLabsContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Head of Engineering<BlueSpan> @ USC TroyLabs</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>January 2021 - May 2022</ExperienceTimeHeader>
                <ExperienceList>
                    <li>I was responsible for leading all engineering initiatives within TroyLabs, USC’s premier entrepreneurship organization. During my time, I scaled and managed a team of 7 engineers.</li>
                    <li>Oversaw and contributed the majority of development for a USC startup database and internal admin platform that has now launched and drives many of TroyLabs' internal processes. The platform and database have been hosted through AWS.</li>
                    <li>Contributed to and managed development of the <a href="demo.troylabs.vc">demo.troylabs.vc</a> website used for DEMO day, USC's largest annual startup exhibition. Also managed and maintained the main TroyLabs website at <a href="troylabs.vc">troylabs.vc</a>.</li>
                    <li>Implemented software processes within TroyLabs including usage of AWS, Jira, a robust pytest infrastructure, Sentry error logging, Sendgrid, and more. I lead engineering team meetings, project roadmapping, sprint planning, and also reviewed all code before merging.</li>
                    <li>I also continued to regularly consult with companies working with TroyLabs, helping them build out their tech stacks.</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const NorthropSWEContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>SWE Intern<BlueSpan> @ Northrop Grumman</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>Summer 2019, 2020</ExperienceTimeHeader>
                <ExperienceList>
                    <li>Currently maintaining a <strong>Secret</strong> level security clearance.</li>
                    <li>Built industry grade applications with Python, mySQL, PERL, and LabView.</li>
                    <li>Served as the Team Lead for 11 interns in Explore Aerospace 2020 event. Earned Best Overall award out of 35 teams of interns.</li>
                    <li>Developed a full crosslink configuration and radiometric data test set complete with GUI in Python, utilizing web socket commands.</li>
                    <li>Implemented an object-oriented command constraints parser for state assurance on a payload.</li>
                    <li>Lead interns in developing a software solution to improve company-wide system cybersecurity posture assessments.</li>
                    <li>Automated aggregation of assessment data, identification of vulnerabilities, and risk mitigation workflow generation.</li>

                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const MBSGContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <ExperienceContentHeader>Senior Advisor<BlueSpan> @ USC Marshall Business Student Government</BlueSpan></ExperienceContentHeader>
                <ExperienceTimeHeader>January 2019 - May 2021</ExperienceTimeHeader>
                <ExperienceList>
                    <li>As an experienced member, I provide guidance to MBSG leaders and initiatives throughout the year.</li>
                    <li>Previously served as VP and Head of Professional Affairs.</li>
                    <li>Coordinated and hosted workshops and events on campus for students’ professional growth (e.g. resume workshops, Freshman Business Week).</li>
                    <li>Managed and utilized corporate relationships within Marshall Business School to help students (e.g. Marshall corporate contacts database, KPMG case competition)</li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}

export const SkillsContentElement = ({ isActive }) => {
    return (
        <>
            <ExperienceContent isActive={isActive}>
                <BiggerExperienceContentHeader><BlueSpan>My Skills</BlueSpan></BiggerExperienceContentHeader>
                <ExperienceList>
                    <li><strong>Languages/Frameworks: </strong>​Python, TypeScript, Go, Java, C++, C, React Native, Express + Node.js, Swift, JavaScript, GraphQL, gRPC </li>
                    <li><strong>Web Design: </strong>React (used for this website), NextJS, Vue.js, Nuxt, Angular.js, Redux, HTML, CSS, SASS, XML, Bootstrap, JSP, Java Servlets, jQuery, AJAX, Webflow, Jinja templates, WTForms, Memcaching</li>
                    <li><strong>Databases: </strong>PostgreSQL, mySQL, MongoDB, DynamoDB, AWS RDS, AWS Aurora, Firebase, Peewee ORM, MS SQL Server, UML, ClearDB</li>
                    <li><strong>AWS: </strong>RDS, Lambdas, Serverless, S3, Cloudfront, SQS, Elastic Container Service, Secrets Manager, Systems Manager, Route 53, ElastiCache, Elastic Beanstalk, Amplify, IVS, MediaLive, MediaConvert, CloudWatch, etc.</li>
                    <li><strong>Mobile: </strong>React Native, Swift, SwiftUI, UIKit, Firebase Authentication, Firestore, Jest, React Native Testing Library, Expo</li>
                    <li><strong>Other: </strong>Google Cloud, Buildkite, S3, Datadog, Terraform, Jira, Docker, LaunchDarkly, unit testing (pytest, jest), lerna, Flask, web scraping, pydocs, WordPress, VBA, Hosting with Heroku, GitHub Pages, Stripe</li>
                    <li><strong>GitHub: </strong>See some of my work on GitHub! My username is <a href="https://github.com/sjgutta" target="_blank" rel="noreferrer">@sjgutta.</a></li>
                </ExperienceList>
            </ExperienceContent>
        </>
    )
}
