import styled from 'styled-components'

export const ExperienceContainer = styled.div`
    background-image: url(${({ img }) => (img)});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 120vh;
    
    @media screen and (max-width: 768px) {
        background-position: center;
    }

    @media screen and (max-width: 1325px) {
        height: auto;
        overflow: auto;
    }
`

export const ExperienceOpacityApplier = styled.div`
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
`

export const ExperienceWrapper = styled.div`
    border: 2px solid black;
    opacity: 90%;
    height: 100%;
    overflow: auto;
    margin-right: 10vw;
    margin-left: 10vw;
    background-color: white;
    z-index: 1;
    clear: both;

    @media screen and (max-width: 1325px) {
        height: 90vh;
        overflow: auto;
    }

    @media screen and (max-width: 1200px) {
        height: 95vh;
        overflow: auto;
    }

    @media screen and (max-width: 1100px) {
        height: 100vh;
        overflow: auto;
    }

    @media screen and (max-width: 1000px) {
        height: 110vh;
        overflow: auto;
    }

    @media screen and (max-width: 775px) {
        height: 120vh;
        overflow: auto;
    }

    @media screen and (max-width: 725px) {
        height: 130vh;
        overflow: auto;
    }

    @media screen and (max-width: 680px) {
        height: 145vh;
        overflow: auto;
    }

    @media screen and (max-width: 580px) {
        height: 155vh;
        overflow: auto;
    }

    @media screen and (max-width: 520px) {
        height: 160vh;
        overflow: auto;
    }
`

export const ExperienceHeader = styled.h1`
    color: rgba(21, 175, 252, 1);
    opacity: 100%;
    font-size: 50px;
    padding-left: 3vw;
    padding-top: 3vh;
`
export const ExperienceMenuContainer = styled.div`
    padding-left: 5vw;
    padding-top: 5vh;
    float: left;
    width: auto;
    height: 60vh;
`

export const ExperienceMenuItem = styled.div`
    color: ${({ isActive }) => (isActive ? 'blue' : 'black')};
    border-left: ${({ isActive }) => (isActive ? '3px solid blue' : '2px solid gray')};
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 20vw;

    &:hover {
        background-color: lightgray;
        color: blue;
        font-weight: bold;
        cursor: pointer;
    }
`

export const LogoImage = styled.img`
    height: 5vh;
    padding-right: 1vw;
`

export const ExperienceContent = styled.div`
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    float: right;
    width: 50vw;
    padding-top: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    height: 60vh;
`

export const ExperienceContentHeader = styled.h1`
    font-size: 3vh;
`

export const BiggerExperienceContentHeader = styled(ExperienceContentHeader)`
    font-size: 5vh;
`

export const BlueSpan = styled.span`
    color: rgba(21, 175, 252, 1);
`

export const ExperienceTimeHeader = styled.h2`
    padding-top: 0.2vh;
    font-size: 2.5vh;
    font-weight: lighter;
`

export const ExperienceList = styled.ul`
    padding-top: 3vh;
    padding-left: 1.2vw;

    li::marker {
        color: rgba(21, 175, 252, 1);
        content: "►";
    }

    li {
        padding-bottom: 2vh;
        padding-left: 1vw;
    }
`
