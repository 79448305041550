import React from 'react'
import { ContactViewContainer, ContactHeader, Divider, ContactParagraph, ProfileIconsContainer, IconsRow, 
    IconContainer, LinkedInIcon, GitHubIcon, InstagramIcon, MediumIcon, ContactBottomParagraph } from './ContactElements'

const ContactView = () => {
    return (
        <>
            <ContactViewContainer id="contact">
                <ContactHeader>Contact Me</ContactHeader>
                <Divider></Divider>
                <ContactParagraph>
                    Feel free to get in touch with me with any questions, ideas, or just to connect!<br /><br />
                    My inbox is always open so feel free to email me using <a href="mailto:sjgutta@gmail.com">sjgutta@gmail.com</a>!<br /><br />
                    You can also use the icons below to check out my profiles on other platforms.
                </ContactParagraph>
                <ProfileIconsContainer>
                    <IconsRow>
                        <IconContainer md={ true } sm={6} xs={6}>
                            <a href="https://www.linkedin.com/in/sajan-gutta/" target="_blank" rel="noreferrer">
                                <LinkedInIcon size={"100%"} />
                            </a>
                        </IconContainer>
                        <IconContainer md={ true } sm={6}  xs={6}>
                            <a href="https://github.com/sjgutta" target="_blank" rel="noreferrer">
                                <GitHubIcon size={"100%"} />
                            </a>
                        </IconContainer>
                        <IconContainer md={ true } sm={6}  xs={6}>
                            <a href="https://www.instagram.com/sjgutta/?hl=en" target="_blank" rel="noreferrer">
                                <InstagramIcon size={"100%"} />
                            </a>
                        </IconContainer>
                        <IconContainer md={ true } sm={6}  xs={6}>
                            <a href="https://gutta.medium.com" target="_blank" rel="noreferrer">
                                <MediumIcon size={"100%"} />
                            </a>
                        </IconContainer>
                    </IconsRow>
                </ProfileIconsContainer>
                <ContactBottomParagraph>
                    <strong>Website Designed & Created by Sajan Gutta.</strong>
                </ContactBottomParagraph>
            </ContactViewContainer>
        </>
    )
}

export default ContactView;
