import React from 'react'
import {
    ExperienceContainer, ExperienceOpacityApplier, ExperienceWrapper,
    ExperienceHeader, ExperienceMenuContainer, ExperienceMenuItem, LogoImage
} from './ExperienceElements'
import {
    EducationContentElement, FreelanceContentElement, MedworxContentElement, AutographContentElement, DiscotechContentElement, NorthropSWEContentElement, MBSGContentElement,
    SkillsContentElement, SamsaraContentElement, TroyLabsContentElement
} from './ExperienceContentElements'
import backgroundImage from '../../images/ocean.jpg';
import sjguttaLogo from '../../images/logo.png'
import medworxLogo from '../../images/logo-images/worx360.png'
import autographLogo from '../../images/logo-images/autograph.jpeg'
import disco from '../../images/logo-images/discotech.png'
import uscLogo from '../../images/logo-images/usc.png'
import samsaraLogo from '../../images/logo-images/samsara.png'
import mbsgLogo from '../../images/logo-images/mbsg.png'
import northropLogo from '../../images/logo-images/northrop.jpg'
import troylabsLogo from '../../images/logo-images/troylabs.png'

const ExperienceView = () => {
    const [activeExperience, setActiveExperience] = React.useState('Education');

    const handleMenuClick = event => {
        setActiveExperience(event.target.innerText);
    };

    return (
        <>
            <ExperienceContainer img={backgroundImage} id="experience">
                <ExperienceOpacityApplier>
                    <ExperienceWrapper>
                        <ExperienceHeader>
                            Experience
                        </ExperienceHeader>
                        <ExperienceMenuContainer>
                            <ExperienceMenuItem isActive={activeExperience === "Education"} onClick={handleMenuClick}>
                                <LogoImage src={uscLogo} />
                                Education
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "SJ GUTTA LLC"} onClick={handleMenuClick}>
                                <LogoImage src={sjguttaLogo} />
                                SJ GUTTA LLC
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "worx360"} onClick={handleMenuClick}>
                                <LogoImage src={medworxLogo} />
                                worx360
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "Autograph"} onClick={handleMenuClick}>
                                <LogoImage src={autographLogo} />
                                Autograph
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "Discotech"} onClick={handleMenuClick}>
                                <LogoImage src={disco} />
                                Discotech
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "Samsara"} onClick={handleMenuClick}>
                                <LogoImage src={samsaraLogo} />
                                Samsara
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "USC TroyLabs"} onClick={handleMenuClick}>
                                <LogoImage src={troylabsLogo} />
                                USC TroyLabs
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "Northrop Grumman"} onClick={handleMenuClick}>
                                <LogoImage src={northropLogo} />
                                Northrop Grumman
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "USC MBSG"} onClick={handleMenuClick}>
                                <LogoImage src={mbsgLogo} />
                                USC MBSG
                            </ExperienceMenuItem>
                            <ExperienceMenuItem isActive={activeExperience === "Skills"} onClick={handleMenuClick}>
                                Skills
                            </ExperienceMenuItem>
                        </ExperienceMenuContainer>
                        <EducationContentElement isActive={activeExperience === "Education"} />
                        <FreelanceContentElement isActive={activeExperience === "SJ GUTTA LLC"} />
                        <MedworxContentElement isActive={activeExperience === "worx360"} />
                        <AutographContentElement isActive={activeExperience === "Autograph"} />
                        <DiscotechContentElement isActive={activeExperience === "Discotech"} />
                        <SamsaraContentElement isActive={activeExperience === "Samsara"} />
                        <TroyLabsContentElement isActive={activeExperience === "USC TroyLabs"} />
                        <NorthropSWEContentElement isActive={activeExperience === "Northrop Grumman"} />
                        <MBSGContentElement isActive={activeExperience === "USC MBSG"} />
                        <SkillsContentElement isActive={activeExperience === "Skills"} />
                    </ExperienceWrapper>
                </ExperienceOpacityApplier>
            </ExperienceContainer>
        </>
    )
}

export default ExperienceView
