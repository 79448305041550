import React from 'react'
import {Nav, BaseNavLink, NavLink, Bars, NavMenu, NavBtn, NavBtnLink} from './NavbarElements';
import logo from '../../images/logo.png';
import { animateScroll as scroll } from 'react-scroll';
import resume from '../../images/resume.pdf';

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const Navbar = ({ toggle }) => {
    const [prevScrollPos, setPrevScrollPos] = React.useState(0);
    const [visible, setVisible] = React.useState(true); 

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    //handler for scrolling, using debounce to not run too often
    const handleScroll = debounce(() => {
        const currentScrollPos = window.pageYOffset;
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 20) || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
    }, 50);
    
    //using effect to call scroll handler when scroll position changes
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos, visible, handleScroll]);

    return (
        <>
            <Nav isVisible={visible}>
                <BaseNavLink to="/" onClick={toggleHome}>
                    <img src={logo} alt="logo" />
                </BaseNavLink>
                <Bars onClick={toggle}/>
                <NavMenu>
                    <NavLink to="about" smooth={true} duration={800} spy={true} exact='true' activeStyle>
                        About
                    </NavLink>
                    <NavLink to="experience" smooth={true} duration={1600} spy={true} exact='true' activeStyle>
                        Experience
                    </NavLink>
                    <NavLink to="projects" smooth={true} duration={2400} spy={true} exact='true' activeStyle>
                        Projects
                    </NavLink>
                    <NavLink to="articles" smooth={true} duration={3200} spy={true} exact='true' activeStyle>
                        Articles
                    </NavLink>
                    <NavLink to="contact" smooth={true} duration={4000} spy={true} exact='true' activeStyle>
                        Contact
                    </NavLink>
                </NavMenu>
                <NavBtn>
                    <NavBtnLink href={resume} target="_blank" rel="noreferrer">Resume</NavBtnLink>
                </NavBtn>
            </Nav>
        </>
    )
}

export default Navbar;
