import React from 'react'
import { ProjectContainer, ProjectMainImage, ProjectMainImageFilter, ProjectContent, ProjectHeader, 
    ProjectDescription, ProjectImageContainer, ProjectLinkIcon } from './ProjectViewElements'
import ScoreboardImage from '../../images/project-images/scoreboard.png';
import GitHubProfile from '../../images/project-images/github-profile.png';
import MacAPIToolImage from '../../images/project-images/macapitool.png';
import JobBoardImage from '../../images/project-images/job-board.png';

export const ProductJobsBoardView = () => {
    return (
        <>
            <ProjectContainer>
                <ProjectContent>
                    <ProjectHeader>
                        Product Jobs Board
                    </ProjectHeader>
                    <ProjectDescription>
                        Built the frontend, backend, and CMS for a job board I run as a side project The job board is focused on product-related roles.
                        <br /><br /><strong>NextJS, React, Express, Node.js, MongoDB</strong>
                    </ProjectDescription>
                </ProjectContent>
                <a href="https://productmanager.jobs" target="_blank" rel="noreferrer">
                    <ProjectImageContainer projHeight='45vh' imgWidth='50vw' isRight={ true }>
                        <ProjectMainImage src={ JobBoardImage }/>
                        <ProjectMainImageFilter />
                        <ProjectLinkIcon />
                    </ProjectImageContainer>
                </a>
            </ProjectContainer>
        </>
    )
}

export const PersonalScoreboardView = () => {
    return (
        <>
            <ProjectContainer>
                <ProjectContent isRight={ true }>
                    <ProjectHeader>
                        Personal Scoreboard
                    </ProjectHeader>
                    <ProjectDescription>
                        A web application for ​sports fans to select their favorite teams and view the live scores they care about in a scoreboard view tailored to their specific interests. 
                        A convenient macOS Menu Bar Application will also be provided for direct scoreboard access anywhere on a user’s Mac computer.
                        <br /><br /><strong>Python, Flask, mySQL, JavaScript, Heroku, ClearDB, Swift</strong>
                    </ProjectDescription>
                </ProjectContent>
                <a href="http://www.mypersonalscoreboard.com" target="_blank" rel="noreferrer">
                    <ProjectImageContainer projHeight='45vh' imgWidth='50vw' >
                        <ProjectMainImage src={ ScoreboardImage }/>
                        <ProjectMainImageFilter />
                        <ProjectLinkIcon />
                    </ProjectImageContainer>
                </a>
            </ProjectContainer>
        </>
    )
}

export const MacAPIToolView = () => {
    return (
        <>
            <ProjectContainer>
                <ProjectContent>
                    <ProjectHeader>
                        Mac API Tool
                    </ProjectHeader>
                    <ProjectDescription>
                        The Mac API Tool is a lightweight menu bar application designed for exploring API endpoints and performing simple requests. 
                        It provides users with a menu bar icon that can be easily accessed for improved productivity. Currently compatible with macOS 11.0 and later (compatibility with earlier versions will come in future releases).
                        <br /><br /><strong>Swift, SwiftUI, Xcode, GitHub</strong>
                    </ProjectDescription>
                </ProjectContent>
                <a href="https://github.com/sjgutta/mac-api-tool" target="_blank" rel="noreferrer">
                    <ProjectImageContainer projHeight='45vh' imgWidth='50vw' isRight={ true }>
                        <ProjectMainImage src={ MacAPIToolImage }/>
                        <ProjectMainImageFilter />
                        <ProjectLinkIcon />
                    </ProjectImageContainer>
                </a>
            </ProjectContainer>
        </>
    )
}

export const GitHubPortfolioView = () => {
    return (
        <>
            <ProjectContainer>
                <ProjectContent isRight={ true }>
                    <ProjectHeader>
                        GitHub Portfolio
                    </ProjectHeader>
                    <ProjectDescription>
                        While I have only listed some projects here, I have completed numerous other small projects which can be found on my personal GitHub. Many of these are in the sjgutta-personal-portfolio repository.
                        My GitHub username is @sjgutta. Click the image for this project to visit my profile!
                        <br /><br /><strong>Python, Java, C++, JavaScript, mySQL, HTML, CSS, etc.</strong>
                    </ProjectDescription>
                </ProjectContent>
                <a href="https://github.com/sjgutta" target="_blank" rel="noreferrer">
                    <ProjectImageContainer projHeight='45vh' imgWidth='50vw'>
                        <ProjectMainImage src={ GitHubProfile }/>
                        <ProjectMainImageFilter />
                        <ProjectLinkIcon isWhite={ true }/>
                    </ProjectImageContainer>
                </a>
            </ProjectContainer>
        </>
    )
}