import React from 'react'
import { BlogFeedContainer, BlogFeedRow, MediumArticleContainer, MediumArticleImage, 
    MediumArticleTitle, FeedPageNextArrow, FeedPagePreviousArrow, FeedPageControls } from './BlogElements'
import MediumWelcomeImage from '../../images/medium-images/medium-welcome.jpeg'
import MediumMenubarImage from '../../images/medium-images/medium-menubar.png'
import MediumSortableJSImage from '../../images/medium-images/medium-sortablejs.png'
import MediumCollabFilteringImage from '../../images/medium-images/medium-collaborative-filtering.jpeg'
import MediumAsyncReloadImage from '../../images/medium-images/async-reloading.png'
import MediumMusicKitImage from '../../images/medium-images/apple-music-kit.jpeg'
import ReactMenuLinksImage from '../../images/medium-images/react-menu-links.png'


export const BlogFeed = () => {
    const feed_data = [
        [ReactMenuLinksImage, "Creating a Collapsible + Fixed Link Menu Using React", "https://gutta.medium.com/creating-a-collapsible-fixed-link-menu-using-react-796ef4520dc8"],
        [MediumMusicKitImage, "Using MusicKitJS to Integrate Your Web Application With Apple Music", "https://gutta.medium.com/using-musickitjs-to-integrate-your-web-application-with-apple-music-35740723221e"],
        [MediumAsyncReloadImage, "Asynchronously Updating a Webpage in a Standard HTML/CSS/JS Frontend", "https://medium.com/codestory/asynchronously-updating-a-webpage-in-a-standard-html-css-js-frontend-8496a3388c01"],
        [MediumCollabFilteringImage, "Collaborative Filtering Simplified: The Basic Science Behind Recommendation Systems", "https://gutta.medium.com/collaborative-filtering-simplified-the-basic-science-behind-recommendation-systems-1d7e7c58cd8"],
        [MediumSortableJSImage, "Sortable JS: Creating a Flexible and Intuitive Interface For User Selections", "https://gutta.medium.com/sortable-js-creating-a-flexible-and-intuitive-interface-for-user-selections-4594eab3862c"],
        [MediumMenubarImage, "Building and Deploying a Mac Menu Bar Application", "https://gutta.medium.com/building-and-deploying-a-mac-menu-bar-application-44f9c9906ee0"],
        [MediumWelcomeImage, "Welcome To My Medium Page!",  "https://gutta.medium.com/welcome-to-my-medium-page-9448282fed3c"]
    ]

    const [currentPage, setCurrentPage] = React.useState(1);

    const maxPage = Math.ceil(feed_data.length / 3);

    const increasePage = () => {
        if ( currentPage === maxPage ){
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage + 1);
        }
    };

    const decreasePage = () => {
        if ( currentPage === 1 ){
            setCurrentPage(maxPage);
        } else {
            setCurrentPage(currentPage - 1);
        }
    };

    //calculating index of first displayed item based on current page
    var first_item;
    var second_item;

    if(currentPage * 3 > feed_data.length){
        first_item = feed_data.length - 3;
        second_item = feed_data.length;
    } else {
        first_item = ((currentPage - 1) * 3);
        second_item = currentPage * 3;
    }

    const filtered_feed_data = feed_data.slice(first_item, second_item)

    return (
        <BlogFeedContainer fluid>
            <FeedPageControls>
                <FeedPagePreviousArrow onClick={ decreasePage }/>
                <strong>Articles { first_item + 1 } - { second_item }</strong>&nbsp;of { feed_data.length }
                <FeedPageNextArrow  onClick={ increasePage }/>
            </FeedPageControls>
            <BlogFeedRow className="justify-content-center">
                {filtered_feed_data.map(item => (
                    <MediumArticle 
                        image_url={ item[0] }
                        title={ item[1] }
                        external_link={ item[2] }
                    />
                ))}
            </BlogFeedRow>
        </BlogFeedContainer>
    )
}

const MediumArticle = ({ image_url, title, external_link}) => (
    <MediumArticleContainer md={true} xs={8}>
        <a href={ external_link } target="_blank" rel="noreferrer">
            <MediumArticleImage src={ image_url } />
            <MediumArticleTitle>{ title }</MediumArticleTitle>
        </a>
    </MediumArticleContainer>
);

export default BlogFeed
