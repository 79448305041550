import styled from 'styled-components';

export const LandingContainer = styled.div`
    background-image: url(${({ img }) => (img)});
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    
    @media screen and (max-width: 768px) {
        background-position: center;
    }
`

export const LandingWrapper = styled.div`
    text-align: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
`

export const TextContainer = styled.div`
    text-align: center;
    position: absolute;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
`

export const TextHeader = styled.h1`
    color: black;
    font-size: 50px;
    padding-bottom: 1vh;
`

export const SecondaryHeader = styled.h2`
    color: black;
    font-size: 30px;
    padding-top: 1vh;
`

export const Divider = styled.hr`
    width: 55vw;
    border-top: 2px solid darkgray;

    @media screen and (min-width: 1024px) {
        width: 563px;
    }
`
