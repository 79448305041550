import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, 
    SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute} from './SidebarElements'
import resume from '../../images/resume.pdf';

const SideBar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle} smooth={true} duration={800} spy={true} exact='true' activeStyle>
                        About
                    </SidebarLink>
                    <SidebarLink to="experience" onClick={toggle} smooth={true} duration={1600} spy={true} exact='true' activeStyle>
                        Experience
                    </SidebarLink>
                    <SidebarLink to="projects" onClick={toggle} smooth={true} duration={2400} spy={true} exact='true' activeStyle>
                        Projects
                    </SidebarLink>
                    <SidebarLink to="articles" onClick={toggle} smooth={true} duration={3200} spy={true} exact='true' activeStyle>
                        Articles
                    </SidebarLink>
                    <SidebarLink to="contact" onClick={toggle} smooth={true} duration={4000} spy={true} exact='true' activeStyle>
                        Contact
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute href={resume} target="_blank" rel="noreferrer">Resume</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SideBar
