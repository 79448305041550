import styled from 'styled-components'
import { AiFillLinkedin, AiFillMediumSquare, AiFillInstagram, AiFillGithub } from 'react-icons/ai'
import { Row, Col, Container } from 'react-bootstrap'

export const ContactViewContainer = styled.div`
    min-height: 100vh;
    height: auto;
    background-color: white;
    position: relative;
    z-index: 999;
`

export const ContactHeader = styled.h1`
    color: rgba(21, 205, 252, 1);
    text-align: center;
    padding-top: 5vh;
    padding-bottom: 2vh;
    font-size: 7.5vh;
`

export const Divider = styled.hr`
    width: 50vw;
    margin: auto;
    border-top: 1px solid darkgray;

    @media screen and (min-width: 1024px) {
        width: 512px;
    }
`

export const ContactParagraph = styled.div`
    text-align: center;
    padding-top: 5vh;
    padding-left: 20vw;
    padding-right: 20vw;
`

export const ContactBottomParagraph = styled(ContactParagraph)`
    padding-bottom: 5vh;
`

export const ProfileIconsContainer = styled(Container)`
    padding-top: 9vh;
    padding-bottom: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    height: auto;
    position: relative;
`

export const IconsRow = styled(Row)`
    margin-left: 2vw;
    margin-right: 2vw;
`

export const IconContainer = styled(Col)`
    height: 20vh;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5vh;
`

export const LinkedInIcon = styled(AiFillLinkedin)`
    text-decoration: none;
    color: black;
    
    &:hover {
        color: blue;
        cursor: pointer;
    }
`

export const GitHubIcon = styled(AiFillGithub)`
    text-decoration: none;
    color: black;
    
    &:hover {
        color: orange;
        cursor: pointer;
    }
`

export const InstagramIcon = styled(AiFillInstagram)`
    text-decoration: none;
    color: black;
    
    &:hover {
        color: pink;
        cursor: pointer;
    }
`

export const MediumIcon = styled(AiFillMediumSquare)`
    text-decoration: none;
    color: black;
    
    &:hover {
        color: #64ffda;
        cursor: pointer;
    }
`