import React from 'react'
import { BlogContainer, BlogOpacityApplier, BlogWrapper, BlogHeader } from './BlogElements'
import backgroundImage from '../../images/ocean.jpg';
import { BlogFeed } from './BlogFeed'

const BlogView = () => {
    return (
        <>
            <BlogContainer img={backgroundImage} id="articles">
                <BlogOpacityApplier>
                    <BlogWrapper>
                        <BlogHeader>
                            Medium Articles
                        </BlogHeader>
                        <BlogFeed />
                    </BlogWrapper>
                </BlogOpacityApplier>
            </BlogContainer>
        </>
    )
}

export default BlogView
